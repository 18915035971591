<template>
  <div class="container-fluid px-0 py-2" style="margin-bottom: 100px">
    <div class="topo-itens">
      <div>
        <router-link :to="{ name: 'KitWelcome' }">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-arrow-left"
            style="opacity: 0.5"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
        </router-link>
      </div>
      <div>
        <h3 style="font-weight: 600">Personalize o seu imóvel na planta</h3>
      </div>
    </div>
    <div class="col-12">
      <div class="p-dash-cliente-master">
        <div class="col-12 col-md-12 mx-auto">
          <div class="row">
            <div class="col-12 cancela-col-padding">
              <p class="text-right text-mobile mt-3" style="color: #2f2f2f80">
                É rápido e fácil: você vê na hora as melhores condições para
                realizar um upgrade no apartamento.
              </p>
              <div
                class="grid-item"
                style="margin-top: 30px"
                v-show="!loading"
                data-anima="bottom"
              >
                <div class="imovel">
                  <div class="flex-imovel">
                    <img
                      :src="
                        item_enterprise ? item_enterprise.image_principal : null
                      "
                      alt=""
                    />

                    <img
                      :src="item_enterprise ? item_enterprise.image : null"
                      alt=""
                    />
                  </div>
                </div>

                <div class="conteudo">
                  <div class="dados mb-3">
                    <div
                      class="img-amplia"
                      v-if="unity.tipology && unity.tipology.image"
                    >
                      <a
                        :href="unity.tipology.image"
                        target="_blank"
                        class="open-img"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#fff"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-search"
                        >
                          <circle cx="11" cy="11" r="8"></circle>
                          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                        </svg>
                      </a>
                      <img
                        :src="unity.tipology ? unity.tipology.image : null"
                        alt=""
                      />
                    </div>
                    <div style="min-width: 270px" class="w-mobile-100">
                      <div class="itens-dado">
                        <div class="item-dado">
                          <span>TIPO</span>
                          <p>
                            {{ unity.tipology ? unity.tipology.name : null }}
                          </p>
                        </div>
                        <!-- <div class="item-dado">
                          <span>METRAGEM</span>
                          <p>
                            {{ item_enterprise ? item_enterprise.area : "" }}
                          </p>
                        </div> -->
                      </div>

                      <p class="item-descricao">
                        {{ unity.tipology ? unity.tipology.description : null }}
                      </p>
                    </div>
                    <b-form class="form-item" @submit.prevent="save">
                      <div>
                        <label>Empreendimento</label>
                        <b-form-select
                          v-model="order.enterprise_id"
                          :options="enterprises"
                          v-validate="'required'"
                          name="enterprise"
                          :class="{
                            'is-invalid': errors.has('enterprise'),
                          }"
                        ></b-form-select>
                      </div>
                      <div class="mt-2">
                        <label>Localizamos sua unidade</label>
                        <b-form-select
                          v-model="order.unity_id"
                          :options="unities"
                          v-validate="'required'"
                          name="unity"
                          :class="{ 'is-invalid': errors.has('unity') }"
                        ></b-form-select>
                      </div>

                      <b-button type="submit">INICIAR PERSONALIZAÇÃO</b-button>
                    </b-form>
                  </div>
                  <BaseClientKitConditions
                    :new_style="true"
                    class="condicoes"
                  />
                </div>
              </div>

              <div
                class="grid-item"
                style="display: flex; margin-top: 80px"
                data-anima="top"
                v-if="loading"
              >
                <div
                  class="col-12 cancela-col-padding"
                  style="text-align: center"
                >
                  <div class="loading">
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserService from "@/services/resources/UserService";
const service = UserService.build();

export default {
  data() {
    return {
      loading: false,
      enterprises: [],
      unities: [],
      order: {
        enterprise_id: null,
        unity_id: null,
        tipology_id: null,
      },
      unity: {
        number: null,
        tipology: {
          name: null,
          description: null,
        },
      },
      item_enterprise: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
  },
  watch: {
    "order.unity_id"() {
      this.selectUnity();
    },
    "order.enterprise_id"() {
      this.selectEnterprise();
    },
  },
  methods: {
    selectEnterprise() {
      this.enterprises.forEach((element) => {
        if (element.value == this.order.enterprise_id) {
          this.item_enterprise = element.item;
        }
      });
    },

    selectUnity() {
      this.unities.forEach((element) => {
        if (element.value == this.order.unity_id) {
          this.unity = element.unity;
          this.order.tipology_id = element.unity.tipology_id;
        }
      });
    },
    fetchUser() {
      this.loading = true;
      let data = {
        id: this.user.id,
      };

      service
        .read(data)
        .then((resp) => {
          this.getEnterprises(resp.enterprises);
          this.getUnities(resp.unities);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setTimeout(() => {
            this.loading = false;
          }, 300);
        });
    },
    getEnterprises(enterprises) {
      this.enterprises = [{ value: null, text: "Selecione" }];

      enterprises.forEach((element) => {
        this.enterprises.push({
          text: element.enterprise.name,
          value: element.enterprise_id,
          item: element.enterprise,
        });
      });

      if (this.enterprises.length >= 2) {
        this.order.enterprise_id = this.enterprises[1].value;
      }

      if (this.$route.query.enterprise != null && this.$route.query.unity != null) {
        this.order.unity_id = this.$route.query.unity
        this.order.enterprise_id =  this.$route.query.enterprise    
      }
    },
    getUnities(unities) {
      this.unities = [{ value: null, text: "Selecione" }];

      unities.forEach((element) => {
        if (
          !element.unity.order ||
          (element.unity.order &&
            (element.unity.order.is_active == 0 ||
              element.unity.order.deleted == 1))
        ) {
          this.unities.push({
            text: element.unity.number,
            value: element.unity_id,
            unity: element.unity,
          });
        }
      });

      if (this.unities.length >= 2) {
        this.order.unity_id = this.unities[1].value;
        this.order.tipology_id = this.unities[1].unity.tipology_id;
      }
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let order = {
            unity_id: this.order.unity_id,
            enterprise_id: this.order.enterprise_id,
            tipology_id: this.order.tipology_id,
            unity: this.unity,
            enterprise: this.item_enterprise,
          };
          localStorage.order = JSON.stringify(order);
          this.$router.push({
            name: "KitProductV2",
            params: order,
          });
        }
      });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    this.fetchUser(); 
  },
};
</script>

<style>
body {
  background-color: #fff !important;
}
</style>
<style scoped>
.container-fluid {
  min-height: 80vh;
}
.grid-item {
  display: grid;
  grid-template-columns: 1fr 3fr;
  /* gap: 40px; */
}
.imovel {
  margin-left: auto;
  z-index: 1;
}
.imovel .flex-imovel {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.imovel img {
  margin: 0 auto;
}
.imovel img:first-child {
  max-width: 240px;
  height: 320px;
  border-radius: 22px;
  object-fit: cover;
}
.imovel img:last-child {
  max-width: 150px;
}
.conteudo .dados {
  gap: 25px;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr;
  align-items: center;
}
.conteudo img {
  max-width: 220px;
}
.itens-dado {
  display: flex;
  gap: 20px;
  border-bottom: 1px solid #93938f83;
}
.item-dado span {
  color: rgba(147, 147, 143, 0.7);
  font-size: 11px;
  display: block;
  margin-bottom: 5px;
}
.item-dado p {
  color: rgba(46, 46, 46, 0.7);
  font-size: 16px;
}
.item-descricao {
  margin-top: 15px;
  color: rgba(46, 46, 46, 0.7);
  font-size: 13px;
  line-height: 1.5;
}

form select {
  border-radius: 12px;
  height: 65px;
  color: #6c6c6c;
}
form select:hover,
form select:focus {
  border-color: var(--color);
  box-shadow: none;
}
form button {
  width: 100%;
  border-radius: 12px;
  height: 65px;
  margin-top: 12px;
  background: var(--color);
  font-weight: 600;
}
form button:hover {
  background: var(--color);
  filter: brightness(0.95);
}
.topo-itens {
  background: rgba(244, 244, 246, 0.5);
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  border-bottom: 1px solid #f4f4f6;
}
.topo-itens a,
.topo-itens h3 {
  margin: 0;
  color: #797979;
}
.col-12 {
  padding-left: 0;
}
.dados {
  margin-left: -20px;
  z-index: -1;
  background: rgba(244, 244, 246, 0.5);
  padding: 30px 40px;
  padding-left: 60px;
  border-radius: 22px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.dados .img-amplia {
  display: flex;
  align-items: center;
  justify-content: center;
}
.dados .img-amplia .open-img {
  position: absolute;
  width: 50px;
  height: 50px;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  cursor: pointer;
}
.dados .img-amplia img {
  min-width: 220px;
  min-height: 220px;
  background: rgba(244, 244, 246, 0.9);
}
.condicoes {
  margin-top: 30px;
  margin-left: 40px;
}

@media screen and (max-width: 768px) {
  .col-12 {
    padding: initial;
  }
  .topo-itens {
    padding: 20px;
    gap: 15px;
  }
  .topo-itens h3 {
    font-size: 14px;
  }
  .grid-item {
    grid-template-columns: 1fr;
  }
  .imovel {
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .imovel img:first-child {
    max-width: 100% !important;
    height: 150px;
  }
  .conteudo .dados {
    flex-direction: column;
    align-items: flex-start;
  }
  .w-mobile-100 {
    width: 100% !important;
  }
  .text-mobile {
    text-align: left !important;
    padding: 0 !important;
    margin-top: 10px;
  }
  .cancela-col-padding {
    padding: 0;
    margin: 0;
  }
  .cancela-col-padding p {
    margin: 0 20px;
  }
  .conteudo .dados {
    grid-template-columns: 1fr;
    border-radius: 22px !important;
    margin-left: 0 !important;
  }
  .condicoes {
    margin: 0;
    padding: 20px 30px;
  }
  form select,
  form button {
    height: 55px;
    font-size: 13px;
  }
  .item-dado span,
  .item-dado p,
  .item-descricao {
    margin: 10px 0 !important;
  }

  .item-dado p,
  .item-descricao {
    font-size: 13px;
  }
}
</style>